import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";

export const getAllValues = async (accountId, onSuccess, onFailure) => {
    try {
     await (axios.get(`${API_HOST}/values/v1/values/fetch/all?accountId=${accountId}`,
          {
            headers: {
              tbtc: getBaseToken(),
              tstc: getSecureToken()
            }
          }
        ).then(onSuccess)
        .catch(onFailure)
      );
     } catch(e) {
       console.error(e);
       onFailure(e);
     }
}

export const createNewSchema = async (accountId, override, onSuccess, onFailure) => {
  try {
   await (axios.post(`${API_HOST}/values/v1/values?accountId=${accountId}`,
        override,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess)
      .catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}

export const getOverrides = async (accountId, valueName, onSuccess, onFailure) => {
  try {
   await (axios.get(`${API_HOST}/values/v1/values/overrides/${valueName}?accountId=${accountId}`,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess)
      .catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}

export const upsertOverride = async (accountId, override, onSuccess, onFailure) => {
  try {
   await (axios.post(`${API_HOST}/values/v1/values/overrides?accountId=${accountId}`,
        override,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess)
      .catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}

export const deleteOverride = async (accountId, override, onSuccess, onFailure) => {
  try {
   await (axios.post(`${API_HOST}/values/v1/values/overrides/remove?accountId=${accountId}`,
        override,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess)
      .catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}
