import { Header } from "./Header";
import { NavigationSidebar } from "./NavigationSidebar";
import { StyledCard } from "./StyledCard";

export const Page = (params) => {

    const { name } = params;

    return (
        <div style={{
            height: "100vh",
            display: "flex",
            width: "100%",
            background: "#F7F8FC",
            fontFamily: 'Mulish'
        }}>
            <NavigationSidebar style={{
                background: "#363740",
                color: "#F7F8FC",
                width: "18%"
                }}
                items={[
                    {name: "Dashboard"},
                    {name: "Account"},
                ]}
            />
            {/* <StyledCard style={{
                width: "18%",
                height: "11%",
                margin: "5%"
            }}>
                <p>Card</p>
            </StyledCard> */}
            <div style={{width: "80%", marginLeft: "2%", marginRight: "2%"}}>
                <Header pageName={name} />
                {params.children}
            </div>
        </div>
    );
}