import { Page } from "../components/Page"
import React, { useEffect } from 'react';
import { Table, Column, Modal, Input } from 'react-rainbow-components';
import { useState } from "react";
import { Button } from "@mui/material";
import { StyledCard } from "../components/StyledCard";
import { getOverrides, upsertOverride, deleteOverride } from "../api/ValuesService";
import { useParams } from "react-router";
import { Add, Delete, Save, SaveAs, SaveAsRounded } from "@mui/icons-material";


export const ManageValue = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { accountId, valueName } = useParams();

    const [upsertValue, setUpsertValue] = useState();
    const [upsertCoords, setUpsertCoords] = useState();
    
    const [requestsByDay, setRequestsByDay] = useState([{data: []}]);

    const now = new Date();
    now.setDate(now.getDate() - 30);
    

    useEffect(() => {

        getOverrides(accountId, valueName, (resp) => {setData(resp.data); setLoading(false);}, (fail) => {console.log(fail); setLoading(false);});


        // getCoreVitals(1001, time, (resp) => {setData(resp.data); setLoading(false);}, (fail) => {console.log(fail); setLoading(false);});
        // getRequestCountByDay(1001, time, (resp) => {
        //     const d = resp.data;
        //     console.log(d);
        //     const arr = Object.keys(d).map(k => ({x: k, y: [d[k]]}));
        //     console.log("Arr", arr);
        //     setRequestsByDay([{name: 'Requests', data: arr}])
        // }, (fail) => {});
    }, []);

    const saveOverride = () => {
        let obj = {name: valueName, coordinates: upsertCoords, updatedValue: upsertValue};
        upsertOverride(accountId, obj, 
            (resp) => {
                let arr = data;
                arr.push(obj);
                setData(arr);
                setIsModalOpen(false);
                setUpsertCoords();
                setUpsertValue();
            }, (fail) => {console.log(fail); setLoading(false);})
    }

    const removeOverride = (value) => {
        console.log("Attempting to delete ", value)
        deleteOverride(accountId, value, 
            (resp) => {
                let arr = data;
                arr = arr.filter((i) => i !== value);
                setData(arr);
            }, (fail) => {console.log(fail); setLoading(false);})
    }

    const handleValueUpdate = ({ value, row }) => {
        const index = data.findIndex(item => item.coordinates === row.coordinates)
        const newData = [...data];
        newData[index].updatedValue = value;
        // Ship update to BE
        upsertOverride(accountId, newData[index], 
            (resp) => {
                
            }, (fail) => {console.log(fail); setLoading(false);})
        setData(newData);
    } 

    const validateType = (value, type) => {
        switch (type) {
            case 'INT': {

            }
        }
    }

    return (
        <Page name="Overview">
                <div className="rainbow-p-bottom_xx-large">
                    <StyledCard>
                    <Button style={{width: "100%"}} onClick={() => setIsModalOpen(true)}><div> <Add /><p>Add Override</p></div></Button>
                        <Table isLoading={loading} data={data} keyField="coordinates" variant="listview">
                            {/* <Column header="Name" field="name" /> */}
                            <Column header="Coordinates" field="coordinates" />
                            <Column onChange={handleValueUpdate} isEditable={true} header="Value" field="updatedValue" />
                            <Column header="" component={(c) =>  <Button style={{width: "100%"}} onClick={() => removeOverride(c.row)}><Delete /></Button>} />
                        </Table>
                    </StyledCard>
                    <Modal id="modal-1" isOpen={isModalOpen} onRequestClose={() => {setIsModalOpen(false)}}>
                        <Input 
                             id="input-component-1"
                             label="Value"
                             placeholder="Value"
                             value={upsertValue}
                             onChange={(v) => setUpsertValue(v.target.value)}
                             className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        />
                        <Input 
                             id="input-component-1"
                             label="Coordinates"
                             placeholder="override:coordinates"
                             value={upsertCoords}
                             onChange={(v) => setUpsertCoords(v.target.value)}
                             className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        />
                        <Button style={{marginLeft: "40%"}} onClick={() => {
                            saveOverride();
                        }}><SaveAsRounded style={{marginRight: "5%"}}></SaveAsRounded> Save</Button>
                    </Modal>
                </div>
        </Page>
    );
}