import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken, getStandardAuthHeaders } from "./User";

export const createApiToken = (accountId, scopes, onSuccess, onFailure) => {
    axios.post(`${API_HOST}/core-services/v1/api-tokens/create`,
    {accountId: accountId, scopes: scopes},  getStandardAuthHeaders())
        .then(function (response) {
            // handle success
            onSuccess(response);
            // let t = apiTokens;
            // t.push(response.data);
            // setApiTokens(t);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            onFailure(error)
        })
        .then(function () {
            // always executed
        }
    );
}

export const getApiTokens = (accountId, onSuccess, onFailure) => {
    axios.get(`${API_HOST}/core-services/v1/api-tokens?accountId=${accountId}`, getStandardAuthHeaders() )
        .then(function (response) {
          // handle success
          onSuccess(response);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          onFailure(error);
        })
        .then(function () {
          // always executed
        });
}