import axios from "axios";
import { useEffect, useState } from "react";
import { Button, MultiSelect, Option } from "react-rainbow-components";
import { useParams } from "react-router-dom";
import { API_HOST } from "../api/API";
import { createApiToken, getApiTokens } from "../api/TokensService";
import { Page } from "../components/Page";
// import Navbar from "../components/navbar";
import { getBackground } from "../components/Theme";


export const Account = () => {

    // When writing to api, selectedScopes.map(s => s.name);
    const [selectedScopes, setSelectedScopes] = useState([]);
    const [apiTokens, setApiTokens] = useState([]);

    const { accountId } = useParams();

    useEffect(() => {
        document.title = `Account - Notics Tracer`;
      }, []);

    useEffect(() => {
        // axios.get(`${API_HOST}/core-services/v1/api-tokens?accountId=${accountId}`)
        // .then(function (response) {
        //   // handle success
        //   setApiTokens(response.data);
        // })
        // .catch(function (error) {
        //   // handle error
        //   console.log(error);
        // })
        // .then(function () {
        //   // always executed
        // });

        getApiTokens(accountId, (response) => {
            setApiTokens(response.data);
            console.log("Tokens: ", response.data);
        }, () => {})
    
      }, []);

    const createNewApiToken = () => {
        createApiToken(accountId, selectedScopes.map(s => s.name), (response) => {
            let t = apiTokens;
            t.push(response.data);
            setApiTokens(t);
        }, (error) => {
            console.log(error);
        });
    }

    return (
        <Page>
        <div style={{}}>
            {/* <Navbar serviceSwitcherEnabled={false} /> */}
            <MultiSelect
            id="multiselect-component-1"
            label="Select the scopes this api key should have"
            placeholder="Scopes"
            style={{maxWidth: "60%"}}
            //className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
            value={selectedScopes}
            onChange={setSelectedScopes}
            
            // bottomHelpText="Select the scopes this api key should have"
            //enableSearch
            >
                 {/* icon={<DashboardIcon />} */}
                <Option name="VALUES_WRITE" label="Values Write" />
                <Option name="VALUES_READ" label="Values Read" />
            </MultiSelect>
            <Button onClick={createNewApiToken}>Create Api Token</Button>
            <div >
                Api Tokens:
                {apiTokens.map(t => {
                    return (
                        <div style={{display: 'flex', padding: "1%", fontSize: "1.5em"}}>
                            <p style={{padding: "1%"}}>{t.token}</p>
                            <p style={{padding: "1%"}}>{t.scopes}</p>
                            <p style={{padding: "1%"}}>{t.createdAt}</p>
                        </div>
                    );
                })}
            </div>
        </div>
        </Page>
    );
}