import { Page } from "../components/Page"
import React, { useEffect } from 'react';
import { Table, Column, Modal, Input, Picklist, Option } from 'react-rainbow-components';
import { useState } from "react";
import { Button } from "@mui/material";
import { StyledCard } from "../components/StyledCard";
import { getAllValues,  getOverrides, upsertOverride, deleteOverride, createNewSchema } from "../api/ValuesService";
import { useNavigate, useParams } from "react-router";
import { Add, AddRounded, Delete, Save, SaveAs, SaveAsRounded } from "@mui/icons-material";


export const Dashboard = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [name, setName] = useState();
    const [defaultValue, setDefaultValue] = useState();
    const [selectedType, setSelectedType] = useState();

    const { accountId } = useParams();

    const navigate = useNavigate();
    
    useEffect(() => {
        getAllValues(accountId, (resp) => {setData(resp.data); setLoading(false);}, (fail) => {console.log(fail); setLoading(false);});
    }, []);

    const createSchema = () => {
        let obj = {name: name, type: selectedType.name, defaultValue: defaultValue};
        createNewSchema(accountId, obj, 
            (resp) => {
                let arr = data;
                arr.push(obj);
                setData(arr);
                setIsModalOpen(false);
                setName();
                setSelectedType();
                setDefaultValue();
            }, (fail) => {console.log(fail); setLoading(false);})
    }

    return (
    <Page name="Overview">
            <div className="rainbow-p-bottom_xx-large">
                <StyledCard>
                <Button style={{width: "100%"}} onClick={() => setIsModalOpen(true)}><div> <Add /><p>Add new value</p></div></Button>
                    <Table isLoading={loading} data={data} keyField="name" variant="listview">
                        <Column header="Name" field="name" />
                        <Column header="Default Value" field="defaultValue" />
                        <Column header="" component={(c) => <Button style={{width: "100%"}} onClick={() => navigate(`/${accountId}/value/${c.row.name}`)}>Manage Overrides</Button>} />
                    </Table>
                </StyledCard>
                <Modal id="modal-1" isOpen={isModalOpen} onRequestClose={() => {setIsModalOpen(false)}}>
                        <Input 
                             id="input-component-1"
                             label="Name"
                             placeholder="Name"
                             value={name}
                             onChange={(v) => setName(v.target.value)}
                             className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        />
                        <Picklist
                            //style={containerStyles}
                            onChange={value => setSelectedType(value)}
                            value={selectedType}
                            label="Select Type"
                            //hideLabel
                        >
                                {/* STRING,
                                INT,
                                LONG,
                                DOUBLE,
                                BOOLEAN,
                                SET,
                                LIST,
                                OBJECT */}
                            <Option name="BOOLEAN" label="Boolean" />
                            <Option name="STRING" label="String" />
                            <Option name="INT" label="Integer" />
                            <Option name="LONG" label="Long" />
                            <Option name="DOUBLE" label="Double" />
                            <Option name="SET" label="Set" />
                            <Option name="LIST" label="List" />
                            <Option name="OBJECT" label="JSON Object" />
                        </Picklist>
                        <Input 
                             id="input-component-1"
                             label="Default Value"
                             placeholder="Default Value"
                             value={defaultValue}
                             onChange={(v) => setDefaultValue(v.target.value)}
                             className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                        />
                        <Button style={{marginLeft: "40%"}} onClick={() => {
                            createSchema();
                        }}><AddRounded style={{marginRight: "5%"}}></AddRounded> Create</Button>
                    </Modal>
            </div>
    </Page>
    );
}