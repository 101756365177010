import { TextField } from "@mui/material";
import React from "react";
// import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from "react";
import Alert from '@mui/material/Alert';
import { registerUser, registerUserWithInvite, getListOfAccountsUserHasAccessTo, acceptInvite } from '../api/UsersService';
import { getUser, setUser, setTokens, setBusinessesUserHasAccessTo } from "../api/User";
import { useNavigate, useLocation } from "react-router";
import * as Panelbear from "@panelbear/panelbear-js";
import { Button } from "react-rainbow-components";

export default function Signup() {

  const ENTER_KEY = "Enter";

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [invite, setInvite] = useState(null);

  let navigate = useNavigate();

  let query = useQuery();

  useEffect(() => {
    if (query.get("code")) {
        const inv = {
            code: query.get("code"),
            expectedEmail: query.get("expectedEmail"),
            accountId: query.get("accountId")
          };
        setInvite(inv);
        // Check to see if this user is already logged in
        let existingUser = getUser();
        if (existingUser) {
            console.log("Accepting invite for already logged in user.")
            acceptInvite(inv, (success) => {
                setSuccess("Successfully accepted invite.")
                getListOfAccountsUserHasAccessTo((success) => {
                    let businessIds = success.data;
                    setBusinessesUserHasAccessTo(businessIds);
                    if (businessIds.length > 0) {
                    let businessId = businessIds[0];
                    setLoading(false);
                    navigate(`${businessId}/promotions`)
                    } else {
                    // This user has access to no accounts... what do we do in this state.
                    setWarning("Successfully logged in, but this user doesn't have access to any accounts.")
                    setLoading(false);
                    }
                    
                }, (apiError) => {
                    // ??? Probably send them to some onboarding screen.
                    setWarning("Successfully logged in, but failed to find business accounts this user has access to.")
                    setError(apiError.response ? apiError.response.data.message : apiError.message)
                    setLoading(false);
                })
            }, (apiError) => {
                setError(apiError.response ? apiError.response.data.message : apiError.message)
            });
        }
    }  
  }, [query])

  const register = () => {
    setLoading(true);
    setError("");
    setSuccess("");

    if (firstName === "" || lastName === "" || email === "" || password === "") {
        setError("Not all of the fields are filled out. Please make sure to fill out each field.")
        setLoading(false);
        return;
    }

    if (invite === null) {
        Panelbear.track("user.register");
        registerUser(firstName, lastName, email, password, (response) => {
        let user = response.data;
        setSuccess("Successfully Logged in."); 
        console.log(JSON.stringify(user.headers));
        setUser(user.user);
        setTokens(user.tokens.baseToken, user.tokens.secureToken);
        getListOfAccountsUserHasAccessTo((success) => {
            let businessIds = success.data;
            setBusinessesUserHasAccessTo(businessIds);
            if (businessIds.length > 0) {
            let businessId = businessIds[0];
            setLoading(false);
            navigate(`${businessId}/promotions`)
            } else {
            // This user has access to no accounts... what do we do in this state.
            setWarning("Successfully logged in, but this user doesn't have access to any accounts.")
            setLoading(false);
            }
            
        }, (apiError) => {
            // ??? Probably send them to some onboarding screen.
            setWarning("Successfully logged in, but failed to find business accounts this user has access to.")
            setError(apiError.response ? apiError.response.data.message : apiError.message)
            setLoading(false);
        })
        }, (apiError) => {setError(apiError.response ? apiError.response.data.message : apiError.message);setLoading(false);});
    } else {
        Panelbear.track("invite.accept");
        registerUserWithInvite(firstName, lastName, email, password, invite, (response) => {
        let user = response.data;
        setSuccess("Successfully Logged in."); 
        console.log(JSON.stringify(user.headers));
        setUser(user.user);
        setTokens(user.tokens.baseToken, user.tokens.secureToken);
        getListOfAccountsUserHasAccessTo((success) => {
            let businessIds = success.data;
            setBusinessesUserHasAccessTo(businessIds);
            if (businessIds.length > 0) {
            let businessId = businessIds[0];
            setLoading(false);
            navigate(`${businessId}/promotions`)
            } else {
            // This user has access to no accounts... what do we do in this state.
            setWarning("Successfully logged in, but this user doesn't have access to any accounts.")
            setLoading(false);
            }
            
        }, (apiError) => {
            // ??? Probably send them to some onboarding screen.
            setWarning("Successfully logged in, but failed to find business accounts this user has access to.")
            setError(apiError.response ? apiError.response.data.message : apiError.message)
            setLoading(false);
        })
        }, (apiError) => {setError(apiError.response ? apiError.response.data.message : apiError.message);setLoading(false);});
    }
    
  };

  return(  
  <div style={{
      width: "100%", 
      height:"1080px",
      backgroundColor: "#FF3CAC",
      backgroundImage: "linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)",
      padding: "1em 0",
      fontSize: "20px",
      textAlign: "center"
      }}>
      <div>
        <div style={{color: "white", fontFamily: "Roboto"}}>
      <h1><b>Values</b></h1>
      </div>

        <div id="login-inputs" style={{borderRadius: "28px", marginLeft: "37.5%", width: "25%", backgroundColor: "white", padding: "3%"}}>
        <div style={{marginBottom: "2.5%"}}>
        <TextField id="firstName" variant="outlined" type="text" label="First Name" value={firstName} onChange={(e) => {setFirstName(e.target.value)}} />
        </div>
        <div style={{marginBottom: "2.5%"}}>
        <TextField id="lastName" variant="outlined" type="text" label="Last Name" value={lastName} onChange={(e) => {setLastName(e.target.value)}} />
        </div>
        <div style={{marginBottom: "2.5%"}}>
        <TextField id="email" variant="outlined" type="email" label="Email" value={email} onChange={(e) => {setEmail(e.target.value)}} />
        </div>
        <div style={{marginBottom: "3%"}}>
        <TextField id="password" variant="outlined" type="password" label="Password" value={password} onKeyPress={(e)=> {if (e.code == ENTER_KEY) {
          register() 
        } 
          }} onChange={(e) => {setPassword(e.target.value)}}/>
        </div>
        <div style={{marginBottom: "3%"}}>
        <Button variant="contained" loading={loading} style={{backgroundColor: "#6A54F3"}} onClick={register}><b>Register</b></Button>
        </div>
        {error === "" ? <div/> : <Alert severity="error">{error}</Alert>}
        {success === "" ? <div/> : <Alert severity="success">{success}</Alert>}
        {warning === "" ? <div/> : <Alert severity="warning">{warning}</Alert>}
        </div> 

    </div>
  </div>
);
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}