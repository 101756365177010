import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import { Application, Sidebar, SidebarItem } from "react-rainbow-components";
import { useNavigate, useParams } from "react-router";
var logo  = require("../assets/white.png");

export const NavigationSidebar = (params) => {

    const { style, items, onChange } = params;

    const [selectedItem, setSelectedItem] = useState('');

    const navigate = useNavigate();

    const { accountId } = useParams();

    return (
        <div style={{
            ...style,
            position: "relative"
        }}>
            
            <div style={{
                height: "10%",
                zIndex: 10,
                top: 0,
                right: 0,
            }}>
            </div>
            <Drawer
                    sx={{
                    width: style.width,
                    // marginTop: "10px",
                    bgcolor: style.background,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: style.width,
                        boxSizing: 'border-box',
                    },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                <img 
                    src={logo}
                    style={{
                        marginTop: "-10%",
                        zIndex: 10,
                        // position: "absolute",
                        height: "150px",
                        background: style.background,
                        stroke: "#FFFFFF",
                        strokeWidth: "1px"
                    }}
                />   
                <List sx={{ bgcolor: style.background, height: "100vh"}}>
                    {(items || []).map(i => 
                    <ListItem button key={i.name}><ListItemText primaryTypographyProps={{color: "#FFFFFF", fontSize: "16px", lineHeight: "20px", letterSpacing: "0.2px", fontFamily: 'Mulish'}} primary={i.name} onClick={()=>navigate(`/${accountId}/${i.name}`)}/></ListItem>)}
                </List>
            </Drawer>
        </div>
    );
}