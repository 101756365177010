import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import * as React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Application } from 'react-rainbow-components';
import { getBackground, getColor } from './components/Theme';
import { Account } from './pages/Account';
import Signup from './pages/Signup';
import Login from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { ManageValue } from './pages/ManageValue';


function App() {
  return (
    <Application theme={{rainbow: {palette: {brand: getColor(), mainBackground: getBackground()}}}}>
      <div style={{
        //background: "white"
        font: 'Roboto',
        background: getBackground(),
        color: getColor(),
        height: "100%",
        clear: "both"
        }}>
        <Router>
            {/* <Navbar /> */}
            <div>
              <Routes>
                <Route path="/:accountId/account" element={<Account />} />
                <Route path="/:accountId/dashboard" element={<Dashboard />} />
                <Route path="/:accountId/value/:valueName" element={<ManageValue />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path="/test/:accountId/insights/:service">
                  <InsightsV2 />
                </Route>
                <Route path="/test/:accountId/insights/">
                  <InsightsV2 />
                </Route>
                <Route path="/test/:accountId/traces/:service">
                  <Traces />
                </Route>
                <Route path="/test/:accountId/traces/">
                  <Traces />
                </Route>
                <Route path="/test">
                  <Test />
                </Route> */}
                {/* TODO: Add 404 page */}
                {/* <Route path="/">
                  <Home />
                </Route> */}
              </Routes>
            </div>
        </Router>
      </div>
    </Application>
  );
}

export default App;
